.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg, #4facff, #73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 40px;
  color: black;
}

.order-card:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  cursor: pointer;
  background: linear-gradient(45deg, #2e80d9, #4e9ae8);
}

.card .card-block {
  padding: 25px;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.link-style {
  text-decoration: none;
  color: inherit;
}

.wrapper {
  padding-top: 40px;
  padding-bottom: 40px;

  &:focus {
    outline: 0;
  }
}

.clash-card {
  background: white;
  width: 300px;
  display: inline-block;
  margin-inline: auto;
  border-radius: 14 + 5;
  position: relative;
  text-align: center;
  box-shadow: -1px 15px 30px -12px black;
  margin-top: 10px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.clash-card.wizard:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.clash-card__image {
  position: relative;
  height: 230px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  display: flex;
  justify-content: center;
}

.clash-card__image--wizard {
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/wizard-bg.jpg');
  img {
    width: 345px;
    left: 0px;
  }
}

.clash-card__level {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 3px;
}

.clash-card__level--wizard {
  color: #4facff;
}

.clash-card__unit-name {
  font-size: 26px;
  color: black;
  font-weight: 900;
}

.clash-card__unit-name > span {
  display: block;
  font-size: 18px;
}

.clash-card__unit-stats--wizard {
  background: #4facff;

  .one-third {
    border-right: 1px solid darken(#4facff, 6%);
  }
}

.clash-card__unit-stats {
  color: white;
  font-weight: 700;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;

  .one-third {
    width: 33%;
    float: left;
    padding: 15px 15px;
  }

  sup {
    position: absolute;
    bottom: 4px;
    font-size: 45%;
    margin-left: 2px;
  }

  .stat {
    position: relative;
    font-size: 24px;
  }

  .stat-value {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }

  .no-border {
    border-right: none;
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.icon-image {
  width: 45px;
  height: 30px;
  background-color: transparent;
}

.avatar-image {
  max-height: 250px;
  max-width: 300px;
}

.navbar .navbar-nav .nav-item .nav-link {
  color: rgb(222, 217, 217);
}

.navbar .navbar-nav .nav-item .nav-link:hover {
  color: white;
}

.icon {
  margin-left: 1rem;
  height: 25px;
  width: 25px;
  color: white;
}

/* CSS for student modal profile on teacher side */
body.modal-open {
  /* overflow: auto !important; Ensure body scroll is enabled */
  padding-right: 0 !important; /* Remove any additional padding-right */
}

.padding {
  padding: 3rem !important;
}

.user-card-full {
  overflow: hidden;
  margin: 0;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
  background: -webkit-gradient(linear, left top, right top, from(#f29263), to(#ee5a6f));
  background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
  padding: 20px 0;
}

.card-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
}

h6 {
  font-size: 14px;
}

.card .card-block p {
  line-height: 25px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  cursor: pointer;
}

.close:hover {
  color: red;
}

.pointer {
  cursor: default;
}

@media only screen and (min-width: 1400px) {
  p {
    font-size: 14px;
  }
}

.card-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card .card-block p {
  line-height: 25px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-40 {
  margin-top: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-40 {
  margin-top: 20px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.initial-circle {
  width: 30px;
  height: 30px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
}

.highlight {
  background-color: lightblue;
  font-weight: bold;
}

.sticky {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

/* Modal drop-down animation */
@keyframes modalDropIn {
  from {
    transform: translateY(-10vh); /* Start from top */
    opacity: 0;
  }
  to {
    transform: translateY(0); /* End at default position */
    opacity: 1;
  }
}

@keyframes modalDropOut {
  from {
    transform: translateY(0); /* Start at default position */
    opacity: 1;
  }
  to {
    transform: translateY(-10vh); /* End at top */
    opacity: 0;
  }
}

/* Apply the animations to the modal */
.modal.modal-drop-in {
  animation: modalDropIn 0.3s ease forwards;
}

.modal.modal-drop-out {
  animation: modalDropOut 0.3s ease forwards;
}

/* Student side css */

.progress-circle {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: progress-animation 2s ease-out forwards;
}

.progress-value {
  position: absolute;
  width: 80%;
  height: 80%;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #007bff;
  z-index: 1;
}

/* Animation for the progress ring NEED TO SORT OUT */
/* @keyframes progress-animation {
  0% {
    background: conic-gradient(#007bff 0%, #007bff 0%, #d9d9d9 0%);
  }
  100% {
    background: conic-gradient(#007bff 0%, #007bff 80%, #d9d9d9 80%);
  }
} */

.progress-headline {
  margin: 25px 50px 10px;
}

.progress-title {
  font-size: 18px;
  font-weight: 700;
  color: #1c2647;
}
.progress {
  height: 30px !important;
  background: #fff;
  border-top: 5px solid #1c2647;
  border-bottom: 5px solid #1c2647;
  border-radius: 0 !important;
  margin-bottom: 25px;
  margin-left: 50px;
  margin-right: 50px;
  overflow: visible !important;
  position: relative;
}
.progress:before,
.progress:after {
  content: '';
  width: 5px;
  background: #1c2647;
  position: absolute;
  top: 0;
  left: -5px;
  bottom: 0;
}
.progress:after {
  left: auto;
  right: -5px;
}
.progress .progress-bar {
  border: none;
  box-shadow: none;
  -webkit-animation:
    2s linear 0s normal none infinite running progress-bar-stripes,
    animate-positive 1s;
  animation:
    2s linear 0s normal none infinite running progress-bar-stripes,
    animate-positive 1s;
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}

.icon {
  width: 40px; /* Adjust as necessary */
  height: 40px; /* Adjust as necessary */
}
